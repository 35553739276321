
.main {
    height: 80vh;
    width: 100vw;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  
}
.imgmain{
    width: 45vw;
    height: inherit;
    display: grid;
    justify-content: center;
    align-items: center;
   
}
.contact {
    width: 55vw;
    padding: 20px;
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.title {
    font-family: 'Poiret One', cursive;
    font-size: 2.5rem;
    padding-bottom: 15px;
    width: 100vw;
    color: #ffc310;
    border-bottom: 1px solid white;
	width: fit-content;
    
}

.sub-title {
    padding: 1rem 4rem;
    text-align: center;
    color: #fff;
}

.bannerimg {
    width: 40vw;
    margin-top: 0px;
}

@media (max-width:900px) {
    .main {
        display: grid;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 80vh;

    }

    .contact {
        width: 100vw;
        height: 20vh;
        display: grid;
        justify-content: center;
        align-items: center;

    }

    .bannerimg {
        width: 50vw;
        height: 30vh;
        margin-top: 0px;
    }

    .imgmain {
        width: 100vw;
        height: 60vh;
        display: grid;
        justify-content: center;
        align-items: center;

    }

    .title {
        font-size: 1.2375rem;
        text-align: center;
        font-family: 'Poiret One', cursive;
        justify-content: center;
        align-items: center;
    }

    .sub-title {
        font-size: 0.9375rem;
        text-align: center;
        font-family: 'Poiret One', cursive;
        justify-content: center;
        align-items: center;
    }

}

@media (max-width:480px) {
    .bannerimg {
        width: 95vw;
        height: 30vh;
        margin-top: 0px;
    }
    .title {
        font-size: 1.2375rem;
        text-align: center;
        font-family: 'Poiret One', cursive;
        
    }

    .sub-title {
        font-size: 0.9375rem;
        text-align: center;
        font-family: 'Poiret One', cursive;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width:1000px){
    .main{
      display: grid;
      justify-content: space-evenly;
    align-items: center;
    height: 90vh;
    width: 100vw;
    }
    .contact{
      width: 100vw;
      height: 30vh;
      display: flex;
      justify-content: center;
      align-items: center;
  
     
    }
    .imgmain{
        width: 100vw;
        height: 60vh;
        display: flex;
        justify-content: center;
        align-items: center;
       }
   .bannerimg{
    width: 100vw;
    height: 60vh;
    margin-top:0px;
   } 
   
   
   .title{
    font-size:1.4375rem;
    text-align: center;
    margin-bottom: .3rem;
   }
   .sub-title{
    font-size:0.9375rem;
    text-align: center;
   }
    
  }