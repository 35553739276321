
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}


.footer-container{
width: 100vw;
height: 50px;
display: flex;
justify-content: center;
align-items: center;
color: #fff;
border-top: 0.5px solid #ffffff22;
text-align: center;
}

@media (max-Width:1000px){
    .div2{
        display:flex;
        justify-content:center;
        align-items:center;
        position: relative;
        bottom:0px;
        height:60px;
        width:100vw;
        background-color:#ffffff22;
        color:#fff;
        font-size: 10px;
     
    }
    .footer-container{
        margin-top: 10%;

    }
    
}